import * as React from 'react';

import { Divider, Typography } from '@mui/material';

import { ReactComponent as ArrowRight } from '../../../media/icons/arrow-right.svg';
import { ReactComponent as Graphic } from '../../../media/request-demo/request-demo-graphic.svg';

const Instructions: React.FC = () => {
    const title = 'Your Journey to Improved Financial Health Starts Here! ';
    const instructions = 'Thank you for your interest in CRAFT! For more information, fill out this form and our team will get back to you soon.';
    

    return (
        <div className='instructions'>
            <div>
                <Typography className='title' variant='h4'>{title}</Typography>
                <div className='divider-section'>
                    <Divider className='divider'/>
                </div>
            </div>
            <div className='summary'>
                <Typography className='text' variant='h6' fontStyle='italic'>{instructions}</Typography>
                <ArrowRight className='arrow'/>
            </div>
            <Graphic className='svg-graphic'/>
        </div>
    );
};

export default Instructions;