import { createTheme } from '@mui/material';
import sassTheme from './_Theme.module.scss';

const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: sassTheme.header_height,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: '800',
                }
            }
        }
    },
    palette: {
        primary: {
            light: sassTheme.palette_primary_light,
            main: sassTheme.palette_primary_main,
            dark: sassTheme.palette_primary_dark,
            contrastText: sassTheme.palette_primary_contrastText,
        },
        secondary: {
            light: sassTheme.palette_secondary_light,
            main: sassTheme.palette_secondary_main,
            dark: sassTheme.palette_secondary_dark,
            contrastText: sassTheme.palette_secondary_contrastText,
        },
        text: {
            primary: sassTheme.palette_text_primary,
            secondary: sassTheme.palette_text_secondary,
            disabled: sassTheme.palette_text_disabled,
        },
        background: {
            default: sassTheme.palette_background_default,
            paper: sassTheme.palette_background_paper,
        },
    },
    shadows: [
        'none', sassTheme.shadows_1,sassTheme.shadows_2,sassTheme.shadows_3,sassTheme.shadows_4,
        sassTheme.shadows_5,sassTheme.shadows_6,sassTheme.shadows_7,sassTheme.shadows_8,sassTheme.shadows_9,
        sassTheme.shadows_10,sassTheme.shadows_11,sassTheme.shadows_12,sassTheme.shadows_13,sassTheme.shadows_14,
        sassTheme.shadows_15,sassTheme.shadows_16,sassTheme.shadows_17,sassTheme.shadows_18,sassTheme.shadows_19,
        sassTheme.shadows_20,sassTheme.shadows_21,sassTheme.shadows_22,sassTheme.shadows_23,sassTheme.shadows_24,
    ],
    typography: {
        fontFamily: sassTheme.typography_fontFamily,
        h1: {
            fontFamily: sassTheme.typography_h1_fontFamily,
            fontWeight: parseInt(sassTheme.typography_h1_fontWeight),
            fontSize: sassTheme.typography_h1_fontSize,
            lineHeight: sassTheme.typography_h1_lineHeight,
            letterSpacing: sassTheme.typography_h1_letterSpacing,
        },
        h2: {
            fontFamily: sassTheme.typography_h2_fontFamily,
            fontWeight: parseInt(sassTheme.typography_h2_fontWeight),
            fontSize: sassTheme.typography_h2_fontSize,
            lineHeight: sassTheme.typography_h2_lineHeight,
            letterSpacing: sassTheme.typography_h2_letterSpacing,
        },
        h3: {
            fontFamily: sassTheme.typography_h3_fontFamily,
            fontWeight: parseInt(sassTheme.typography_h3_fontWeight),
            fontSize: sassTheme.typography_h3_fontSize,
            lineHeight: sassTheme.typography_h3_lineHeight,
            letterSpacing: sassTheme.typography_h3_letterSpacing,
        },
        h4: {
            fontFamily: sassTheme.typography_h4_fontFamily,
            fontWeight: parseInt(sassTheme.typography_h4_fontWeight),
            fontSize: sassTheme.typography_h4_fontSize,
            lineHeight: sassTheme.typography_h4_lineHeight,
            letterSpacing: sassTheme.typography_h4_letterSpacing,
        },
        h5: {
            fontFamily: sassTheme.typography_h5_fontFamily,
            fontWeight: parseInt(sassTheme.typography_h5_fontWeight),
            fontSize: sassTheme.typography_h5_fontSize,
            lineHeight: sassTheme.typography_h5_lineHeight,
            letterSpacing: sassTheme.typography_h5_letterSpacing,
        },
        h6: {
            fontFamily: sassTheme.typography_h6_fontFamily,
            fontWeight: parseInt(sassTheme.typography_h6_fontWeight),
            fontSize: sassTheme.typography_h6_fontSize,
            lineHeight: sassTheme.typography_h6_lineHeight,
            letterSpacing: sassTheme.typography_h6_letterSpacing,
        },
        subtitle1: {
            fontFamily: sassTheme.typography_subtitle1_fontFamily,
            fontWeight: parseInt(sassTheme.typography_subtitle1_fontWeight),
            fontSize: sassTheme.typography_subtitle1_fontSize,
            lineHeight: sassTheme.typography_subtitle1_lineHeight,
            letterSpacing: sassTheme.typography_subtitle1_letterSpacing,
        },
        subtitle2: {
            fontFamily: sassTheme.typography_subtitle2_fontFamily,
            fontWeight: parseInt(sassTheme.typography_subtitle2_fontWeight),
            fontSize: sassTheme.typography_subtitle2_fontSize,
            lineHeight: sassTheme.typography_subtitle2_lineHeight,
            letterSpacing: sassTheme.typography_subtitle2_letterSpacing,
        },
        body1: {
            fontFamily: sassTheme.typography_body1_fontFamily,
            fontWeight: parseInt(sassTheme.typography_body1_fontWeight),
            fontSize: sassTheme.typography_body1_fontSize,
            lineHeight: sassTheme.typography_body1_lineHeight,
            letterSpacing: sassTheme.typography_body1_letterSpacing,
        },
        body2: {
            fontFamily: sassTheme.typography_body2_fontFamily,
            fontWeight: parseInt(sassTheme.typography_body2_fontWeight),
            fontSize: sassTheme.typography_body2_fontSize,
            lineHeight: sassTheme.typography_body2_lineHeight,
            letterSpacing: sassTheme.typography_body2_letterSpacing,
        },
        button: {
            fontFamily: sassTheme.typography_button_fontFamily,
            fontWeight: parseInt(sassTheme.typography_button_fontWeight),
            fontSize: sassTheme.typography_button_fontSize,
            lineHeight: sassTheme.typography_button_lineHeight,
            letterSpacing: sassTheme.typography_button_letterSpacing,
        },
        caption: {
            fontFamily: sassTheme.typography_caption_fontFamily,
            fontWeight: parseInt(sassTheme.typography_caption_fontWeight),
            fontSize: sassTheme.typography_caption_fontSize,
            lineHeight: sassTheme.typography_caption_lineHeight,
            letterSpacing: sassTheme.typography_caption_letterSpacing,
        },
        overline: {
            fontFamily: sassTheme.typography_overline_fontFamily,
            fontWeight: parseInt(sassTheme.typography_overline_fontWeight),
            fontSize: sassTheme.typography_overline_fontSize,
            lineHeight: sassTheme.typography_overline_lineHeight,
            letterSpacing: sassTheme.typography_overline_letterSpacing,
        }
    },
    shape: {
        borderRadius: parseInt(sassTheme.shape_borderRadius),
    },
    zIndex: {
        modal: 300,
        appBar: 400,
        drawer: 200,
    },
});

export default theme;