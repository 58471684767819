import * as React from 'react';

import {
    Grid,
    Typography,
    TextField as InputField,
    InputBaseComponentProps
} from '@mui/material';

import { PhoneNoFormatter } from '../Utils/NumberFormatter';

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface TextFieldProps {
    name: string;
    value: string;
    label?: string;
    type?: 'text' | 'email' | 'phone';
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    onBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    required?: boolean;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    error?: string;
    xs?: GridSize;
    md?: GridSize;
    lg?: GridSize;
}

const TextField: React.FC<TextFieldProps> = props => {

    const {
        name,
        value,
        type = 'text',
        label = name,
        onChange,
        onBlur,
        required=false,
        multiline = false,
        minRows,
        maxRows,
        error,
        xs = 6,
        md = xs,
        lg = md
    } = props;

    let className = 'input';
    if (multiline) className += ' multiline';
    if (error) className += ' error';

    return (
        <Grid className='text-field' item xs={xs} md={md} lg={lg}>
            <Typography className='label'>{label}{required ? '*' : ''}</Typography>
            <InputField 
                className={className}
                variant='outlined'
                margin='dense'
                name={name} 
                type={type}
                fullWidth
                multiline={multiline}
                minRows={minRows}
                maxRows={maxRows}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputComponent: type =='phone' ? PhoneNoFormatter as unknown as React.ElementType<InputBaseComponentProps>: undefined,
                }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                required={required}
            />
            {error && <div className='error'>{error}</div>}
        </Grid>
    );
};

export default TextField;



