import * as React from 'react';

import { Button, Grid, Typography } from '@mui/material';

import { ReactComponent as QuoteIcon } from '../../../media/icons/white-quote.svg';
import { Link } from 'react-router-dom';

const Overview: React.FC = () => {

    const title = 'What is CRAFT?';
    const summary = 'CRAFT (Clinical Research Activity Financial Tracking) is a clinical trials financial management application used to identify clinical study costs and performance throughout the study lifecycle. Many researchers are great at research but may struggle to expand their research if there is financial strain on their resources. CRAFT is based on our research into the most common areas of financial strain.\n\n'
                        + 'Developed over many years of analysis and experience, CRAFT identifies the most cost effective ways of applying personnel to the most productive efforts and determining number of personnel.';

    const quote = 'My department went from ~$200k in losses to over $300k in surplus on clinical trials solely based on the data provided by CRAFT by following recommendations on how to focus effort and resources.';
    
    const quoteSource = 'The Vice Chair of a Major University Department';
    
    return (
        <>
            <div className='scroll-anchor' id='overview'/>
            <Grid className='overview' container >
                <Grid className='quote-section' item xs={12} md={6}>
                    <QuoteIcon className='quote-icon'/>
                    <Typography variant='h4' className='quote'>{quote}</Typography>
                    <Typography variant='subtitle1' className='quote-source'>&#8212; {quoteSource}</Typography>
                </Grid>
                <Grid className='summary-section' item xs={12} md={6}>
                    <div className='floating-box'>
                        <Typography className='title' variant='h5'>{title}</Typography>
                        <Typography className='summary' variant='body1'>{summary}</Typography>
                        <Button 
                            className='more-btn' 
                            variant='outlined' 
                            component={Link}
                            to='/#endorsement'
                        >
                            Learn More
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default Overview;