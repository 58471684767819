import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';

import { ReactComponent as FinancialSvg } from '../../../media/hero/financial-graphic-green-accent.svg';
import { Link } from 'react-router-dom';

const statement1 = 'Powerful tools for contract negotiators, administrators, researchers, coordinators, and accountants.';
const statement2 = 'Address your current situation and provide insights into the future.';

const Hero: React.FC = () => {
    
    return (
        <>
            <div className='scroll-anchor' id='hero'/>
            <Grid container className='hero'>
                <Grid item container xs={12} className='top-hero'>
                    <Grid item className='title-section' xs={12} md={5}>
                        <div className='tag-line'>
                            <Typography className='line-one' variant='h2' fontStyle='italic'>
                                Stop Guessing
                            </Typography>
                            <Typography className='line-two' variant='h1'>
                                Maximize Your Research Health
                            </Typography>
                        </div>
                        <div>
                            <Button 
                                variant='contained' 
                                color='secondary'
                                size='large'
                                component={Link}
                                to='/request-demo'
                            >
                                Request A Demo
                            </Button>
                        </div>
                    </Grid>
                    <Grid item className='graphic-section' xs={12} md={7}>
                        <FinancialSvg className='financial-svg' height='100%' width='100%'/>                
                    </Grid>
                </Grid>
                <Grid item className='bottom-hero'>
                    <Typography variant='h6' className='statement first'>{statement1}</Typography>
                    <Typography variant='h6' className='statement'>{statement2}</Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Hero;