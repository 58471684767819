import * as React from 'react';

import { Divider, Typography } from '@mui/material';
import { ReactComponent as ArrowRight } from '../../../media/icons/arrow-right.svg';
import danielImg from '../../../media/daniel-woo/daniel-woo-md.jpeg';

const content = {
    title: 'Developed by Health Industry Experts',
    name: 'Dr. Daniel Woo',
    position: 'Vice Chair of Research for Neurology at the University of Cincinnati',
    blurb1: 'Dr. Daniel Woo has been one of the first to challenge the negative rhetoric, research generally loses money.',
    blurb2: 'Dr. Woo pioneered a completely analytic approach by tracking actions and behaviors of numerous research teams. His work proved to be significantly beneficial, and CRAFT was created.'
};

const Endorsement: React.FC = () => {
    
    return (
        <div className='endorsement' id='endorsement'>
            <div className='edm-content-div'>
                <Typography className='title'>{content.title}</Typography>
                <Divider className='divider'/>
                <div className='edm-content'>
                    <div className='edm-image'>
                        <img 
                            src={danielImg} // need larger image, width is 180px
                            alt='Daniel Woo'
                            placeholder='blurred'
                            // layout='fixed'
                            height={300}
                        />
                    </div>
                    <div className='edm-text'>
                        <div className='img-caption'>
                            <div><ArrowRight className='arrow-icon'/></div>
                            <div>
                                <Typography variant='h6'className='img-caption-text'>{content.name}</Typography>
                                <Typography fontStyle='italic' className='img-caption-text'>{content.position}</Typography>
                            </div>
                        </div>
                        <div className='blurbs'>
                            <Typography>{content.blurb1}</Typography>
                            <Typography>{content.blurb2}</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Endorsement;