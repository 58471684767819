import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import Header from './common/Header/Header';
import Footer from './common/Footer/Footer';
import Landing from './features/Landing/Landing';
import NotFound from './features/NotFound/NotFound';
import RequestDemo from './features/RequestDemo/RequestDemo';

const App: React.FC = () => {

    const appRoutes = [
        { path: '*', element: <NotFound />},
        { path: '/', element: <Landing />},
        { path: '/request-demo', element: <RequestDemo />},
    ];

    return (
        <>
            <CssBaseline />
            <Header/>
            <Routes>
                {appRoutes.map((route, idx) => 
                    <Route 
                        key={`route-${idx}`}
                        path={route.path} 
                        element={route.element} 
                    />
                )}
            </Routes> 
            <Footer/>
        </>
    );
};

export default App;