import * as React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import DashImg from '../../../media/carousel/Dashboard_compressed.png';
import StudyHealthImg from '../../../media/carousel/StudyHealthWithLines.png';

const tagline = 'So Powerful And Easy to Use';

const screenshots = [
    { source: DashImg, alt: 'CRAFT dashboard', title: 'Powerful Dashboards', caption: 'Dashboards provide overall Division and Study health, with insights and alerts on what to correct' },
    { source: StudyHealthImg, alt: 'study health graph', title: 'Insightful Predictions', caption: 'CRAFT predicts future study trends, alllowing you to take action before issues arise'},
];

const Carousel: React.FC = () => {

    return (
        <div className='carousel' id='carousel'>
            <div className='csl-content'>
                <Typography variant='h4' className='tagline'>{tagline}</Typography>
                <Grid className='screenshots' container>
                    {screenshots.map((screenshot, idx) => 
                        <Grid className='grid-item' item xs={12} md={6} key={`screenshot-${idx}`}>
                            <div className='box'>
                                <Typography className='title'>{screenshot.title}</Typography>
                                <Typography className='txt'>{screenshot.caption}</Typography>
                                <Box
                                    className='sc'
                                    component='img'
                                    src={screenshot.source}
                                    alt=''
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default Carousel;