import { Typography } from '@mui/material';
import * as React from 'react';

import { ReactComponent as CraftIcon } from '../../../media/logo/craft-icon.svg';

const points = [
    {
        id: 1,
        title: 'Proactively avoid negative trends, Stay on top of your studies', 
        blurb: 'Detailed grids provide information down to each visit and resource while dashboards and summaries provide overall data and trends'
    },
    { 
        id: 2,
        title: 'Maximize Your Resources and see into the future', 
        blurb: 'Tools such as the Simulator allow you to explore options and identify the best path forward for your study'
    },
    { 
        id: 3,
        title: 'Proactively Address Issues and Reduce Risk', 
        blurb: 'CRAFT identifies studies that are losing money, tells you how much funding remains, and alerts you to unproductive resources'
    },
];

const SellingPoint: React.FC = () => {

    return (
        <>
            <div className='scroll-anchor' id='why-craft'/>
            <div className='selling-point'>
                <div className='sp-left'>
                    <Typography className='title' variant='h3'>CRAFT Helps You Meet Your Objectives</Typography>
                    <CraftIcon width='200px'/>
                </div>
                <div className='sp-cards'>
                    {points.map(point => 
                        <div className='sp-card' key={`point-${point.id}`}>
                            <Typography variant='h6' className='sp-card-title'>{point.title}</Typography>
                            <Typography>{point.blurb}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SellingPoint;