import * as React from 'react';

import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Pricing: React.FC = () => {

    const attnGetter = 'Competitive Pricing & Consistent Results';
    const content = 'CRAFT is priced very competitively and is designed to pay for itself through its output.. We are glad to address specific needs or requirements.  Please reach out to us for more information.';
                    
    return (
        <>
            <div className='scroll-anchor' id='pricing'/>
            <div className='pricing'>
                <div className='text-box'>
                    <Typography className='title'>{attnGetter}</Typography>
                    <Typography className='content'>{content}</Typography>
                    <Button className='request-btn'
                        variant='contained' 
                        size='large'
                        component={Link}
                        to='/request-demo'
                    >
                        Get Started
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Pricing;