import * as React from 'react';

import SEO from '../../common/SEO/SEO';
import Carousel from './Carousel/Carousel';
import Endorsement from './Endorsement/Endorsement';
import Hero from './Hero/Hero';
import HowItWorks from './HowItWorks/HowItWorks';
import KeyFeatures from './KeyFeatures/KeyFeatures';
import Overview from './Overview/Overview';
import Pricing from './Pricing/Pricing';
import SellingPoint from './SellingPoint/SellingPoint';

const Landing: React.FC = () => {

    return (
        <div className='landing'>
            <SEO title='Info' />
            <Hero />
            <Overview />
            <HowItWorks />
            <KeyFeatures />
            <SellingPoint />
            <Carousel />
            <Endorsement />
            <Pricing />
        </div>
    );
};

export default Landing;