import * as React from 'react';

import { Grid } from '@mui/material';

import Form from './Form/Form';
import Instructions from './Instructions/Instructions';
import SEO from '../../common/SEO/SEO';

const RequestDemo:React.FC = () => {

    return (
        <div className='request-demo'>
            <SEO title='Request A Demo' />
            <div className='request-demo-contents'>
                <Grid className='content' container>
                    <Grid className='text-section' item md={6} xs={12}>
                        <Instructions/>
                    </Grid>
                    <Grid className='form-section' item md={6} xs={12}>
                        <Form/>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default RequestDemo;