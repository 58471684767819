import * as React from 'react';

import { Typography, Divider } from '@mui/material';

import { CheckCircle } from '@mui/icons-material';

const features = [
    {
        id: 1, 
        title: 'Financial and HR Tracking', 
        blurb: 'Manage resources, costs, activities, and other aspects to do more with your study! Detailed yet easy to use tools allow you to manage and stay on top of all aspects of your study in real time.',
    }, {
        id: 2, 
        title: 'Dashboards & Analytics',
        blurb: 'Powerful analytics, including multi-level dashboards  that give you the big picture, alert you to issues, and provide course-correcting recommendations, and our unique simulator that allows you to perform what-if analysis and see how your proposed changes will impact your study',
    }, {
        id: 3, 
        title: 'Invoicing',
        blurb: 'An optional invoice component, fully integrated, that produces invoices for all study aspects and ensures money does not fall between the cracks',
    }, {
        id: 4, 
        title: 'Security',
        blurb: 'Our 20 years of experience building secure software products for some of the nations most data sensitive agencies ensures that CRAFT meets strict industry standards including full HIPAA compliance',
    }
];

const KeyFeatures: React.FC = () => {

    return (
        <>
            <div className='scroll-anchor' id='key-features'/>
            <div className='key-features'>
                <div className='kf-header'>
                    <Typography className='title'>Key Features</Typography>
                    <Divider className='divider'/>
                </div>
                <div className='kf-cards'>
                    {features.map(feature => 
                        <div className='kf-card' key={`feature-${feature.id}`}>
                            <CheckCircle className='check-icon'/>
                            <Typography variant='h6' className='kf-card-title'>{feature.title}</Typography>
                            <Typography className='kf-card-blurb'>{feature.blurb}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default KeyFeatures;