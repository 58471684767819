import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AppBar, Button, Drawer, Hidden, IconButton, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { ReactComponent as CraftLogo } from '../../media/logo/craft-logo.svg';
import { ReactComponent as SCLogo } from '../../media/sc-logo/sc-logo-black.svg';

const Header: React.FC = () => {

    const location = useLocation();

    React.useEffect(()=> {
        if (location.hash) {
            const elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                elem.scrollIntoView({behavior: 'smooth'});
            }
        } else {
            window.scrollTo({top:0, left:0, behavior: 'smooth'});
        }
    }, [location,]);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

    const links = [
        { to: '/#overview', title: 'About'},
        { to: '/#key-features', title: 'Features'},
        { to: '/#why-craft', title: 'Why CRAFT?'},
        { to: '/#pricing', title: 'Pricing'},
        { to: '/#contact', title: 'Contact Us'},
    ];

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <Hidden mdDown>
                <AppBar className='header app-bar' elevation={trigger ? 3 : 0}>
                    <Toolbar className='toolbar'>
                        <div className='logo-section'>
                            <Link to='/#hero'><CraftLogo/></Link>
                            <div className='sc-tagline'>
                                <Typography className='sc-tagline-text'>Powered By</Typography>
                                <SCLogo width='150px'/>
                            </div>
                        </div>
                        <div className='navigation'>
                            {links.map((link, idx) =>
                                <Link to={link.to} key={`link-${idx}`} className='header-link'>
                                    {link.title}
                                </Link>
                            )}
                            <Button className='header-btn'
                                variant='outlined'
                                color='secondary'
                                size='small'
                                component={Link}
                                to='/request-demo'
                            >
                                Request a Demo
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <Hidden mdUp>
                <AppBar className='header app-bar' elevation={0}>
                    <Toolbar className='toolbar'>
                        <div className='logo-section'>
                            <Link className='header-logo' to='/' onClick={handleCloseDrawer}>
                                <CraftLogo/>
                            </Link>
                            <div className='sc-tagline'>
                                <Typography className='sc-tagline-text'>Powered By</Typography>
                                <SCLogo width='150px'/>
                            </div>
                        </div>
                        <div className='navigation'>
                            <IconButton onClick={toggleDrawer}><MenuIcon /></IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer anchor='top' open={openDrawer}>
                    <div className='header-drawer'>
                        {links.map((link, idx) => 
                            <Link to={link.to} key={`link-${idx}`} className='header-link drawer'>
                                {link.title}
                            </Link>
                        )}
                        <Link className='header-link drawer demo' 
                            to='/request-demo' 
                            onClick={handleCloseDrawer}
                            key='link-demo'
                        >
                            <Typography className='demo-link'>
                                Request a Demo
                            </Typography>
                        </Link>
                    </div>
                </Drawer>
            </Hidden>
        </>
    );
};

export default Header;