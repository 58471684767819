import * as React from 'react';

const NotFound: React.FC = () => {

    return (
        <div>
            Page Not Found
        </div>
    );
};

export default NotFound;