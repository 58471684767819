import * as React from 'react';

import { 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Box,
    Typography, 
    Divider,
    Grid
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BeforeImg from '../../../media/how-it-works/before/contract_overview_prod.png';
import DuringImg from '../../../media/how-it-works/during/ResourceRequirements.png';
import AfterImg from '../../../media/how-it-works/after/Document_Manager.png';

const HowItWorks: React.FC = () => {

    const [expanded, setExpanded] = React.useState<number | false>(0); 

    const beforeText = 'Enter study data or upload bulk data from our template and perform what-if analysis to ensure the contract you will agree to will provide positive returns.';
    const duringText = 'Track study financial and human resource data in real-time, allowing you to shift resources and ensure you will maximize your study funds. ' + 
                        'Use the CRAFT dashboards and simulator to change variables and view the impact. Make data-driven decisions to ensure you are maximizing your funding, avoiding ' + 
                        'losses, and focusing resources on needs';
    const afterText = 'Access study data and documents for review or comparison.  Leverage a study template for another study.';
    
    const sections = [
        {
            id: 0,
            summary: 'Before the Study',
            details: beforeText,
            img: BeforeImg,
            alt: 'Contract Overview',
            caption: 'Assess your study before signing the contract and perform what-if analysis to adjust to your goals'
            
        } , {
            id: 1,
            summary: 'During the Study',
            details: duringText,
            img: DuringImg,
            alt: 'Resource Requirements',
            caption: 'Analytics provide guidance such as re-allocation of resources to maximize your funding'
        } , {
            id: 2,
            summary: 'After the Study',
            details: afterText,
            img: AfterImg,
            alt: 'Document Manager',
            caption: 'Powerful document management capabilities to store study data after completion'
        }
    ];

    const handleChange = (id: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded? id : false);
    };

    const getImage = () => {
        
        if (expanded  === false ) {
            return '';
        } else {
            return (
                <div>
                    <Box 
                        className='img'
                        component='img'
                        src={sections[expanded].img}
                        alt={sections[expanded].alt}
                    />
                    <Typography className='img-caption'>{sections[expanded].caption}</Typography>
                </div>
            );
        }  
    };

    return (
        <>
            <div className='scroll-anchor' id='how-it-works'/>
            <div className='how-it-works'>
                <Typography className='title'>How CRAFT Works</Typography>
                <Divider className='divider'/>
                <Grid className='content' container >
                    <Grid className='text-section' item xs={12} md={6} order={{md: 1, xs: 2}}>
                        {sections.map((section, idx) => (
                            <Accordion 
                                key={`accordion-${idx}`}
                                className='accordion'
                                expanded={expanded === idx}
                                onChange={handleChange(idx)}
                                elevation={0} 
                            >
                                <AccordionSummary className='summary' expandIcon={<ExpandMoreIcon className='expand-icon' fontSize='large'/>}>
                                    <Typography className='text'>{section.summary}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='details'>
                                    <Typography className='text'>{section.details}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                    <Grid className='img-section' item xs={12} md={6} order={{md: 2, xs: 1}}>
                        {getImage()}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default HowItWorks;