import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
    title: string;
    description?: string;
    lang?: string;
    meta?: ConcatArray<{ name: string; content: string; property: undefined; } | { property: string; content: string; name: undefined; }>;
}

/**
 * 
 * @param props 
 * 
 * title: site title
 * description: site description
 * lang: language
 * meta: additional metadata
 * 
 * @returns 
 */
const SEO: React.FC<Props> = props => {

    const { title, description='', lang='en', meta=[] } = props;

    const siteTitle = 'CRAFT Info';

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={`%s | ${siteTitle}`}
            meta={[
                { name: 'description', content: description },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:type', content: 'website' },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:creator', content: siteTitle },
                { name: 'twitter:title', content: title },
                { name: 'twitter:description', content: description },
            ].concat(meta)}
        />
    );
};

export default SEO; 