import * as React from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Grid, Paper, Typography } from '@mui/material';

import TextField from '../../../common/TextField/TextField';

const Form: React.FC = () => {

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        department: '',
        titlePosition: '',
        phoneNumber: '',
        message: ''
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Email must be a valid email').required('Email is required'),
        organization: Yup.string().required('Organization is required'),
        department: Yup.string().required('Department is required'),
        titlePosition: Yup.string().required('Title/Position is required'),
        phoneNumber: Yup.string().min(10, 'Phone number must have 10 digits').notRequired(),
        message: Yup.string().max(255, 'Message cannot exceed 255 characters').notRequired()
    });
    
    const submitForm = () => {
        alert('Submit');
    };

    return (
        <Paper className='request-form'>
            <Typography className='title' variant='h6' fontWeight='bold' >Request A Demo</Typography>
            <Formik 
                initialValues={initialValues} 
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {props => {
                    const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid className='form-fields' container>
                                <TextField 
                                    xs={6}
                                    name='firstName'
                                    label='First Name'
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    error={errors.firstName}
                                />
                                <TextField 
                                    xs={6}
                                    name='lastName'
                                    label='Last Name'
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    error={errors.lastName}
                                />
                                <TextField 
                                    xs={12}
                                    name='email'
                                    label='Email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    error={errors.email}
                                />
                                <TextField 
                                    xs={12}
                                    name='organization'
                                    label='Organization'
                                    value={values.organization}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    error={errors.organization}
                                />
                                <TextField 
                                    xs={12}
                                    name='department'
                                    label='Department'
                                    value={values.department}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    error={errors.department}
                                />
                                <TextField 
                                    xs={6}
                                    name='titlePosition'
                                    label='Title/Position'
                                    value={values.titlePosition}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    error={errors.titlePosition}
                                />
                                <TextField 
                                    xs={6}
                                    name='phoneNumber'
                                    label='Phone Number'
                                    type='phone'
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.phoneNumber}
                                />
                                <TextField 
                                    xs={12}
                                    name='message'
                                    label='Is there anything specific we can address or answer for you?'
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.message}
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                />
                            </Grid>
                            <div className='actions'>
                                <Button 
                                    className='btn-submit' 
                                    type='submit' 
                                    variant='contained' 
                                    disabled={isSubmitting}
                                >
                                    Send Request
                                </Button>
                            </div>
                        </form>
                    ); 
                }}
            </Formik>
        </Paper>
    );
};

export default Form;