import * as React from 'react';

import { Grid, Link, Typography } from '@mui/material';

import { ReactComponent as SCLogo } from '../../media/sc-logo/sc-logo-white.svg';
import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {

    const location = useLocation();

    const [showSummary, setShowSummary] = React.useState<boolean>(false);
    // className prop wasn't used in gatsby app, leaving here in case it wants to be added later
    // const [className, setClassName] = React.useState<string>();

    React.useEffect(() => {
        if (location.pathname=='/') setShowSummary(true);
        else setShowSummary(false);
    }, [location,]);

    const contactProps = showSummary? {
        xs:12,
        md:6,
        order: {xs: 2, md: 1}
    } : {
        xs:12,
        align: 'center'
    };

    const gridClassName = showSummary? 'contact-root' : 'contact-root thin-bar';
    const rootClassName = 'contact'; // className? 'contact' : 'contact ' + className;

    const contactInfo = {
        title: 'Contact Us',
        phone: '(703)-914-5454',
        email: 'craftsupport@simoncomputing.com',
        website: 'www.simoncomputing.com',
    };
    const aboutCompany = {
        title: 'Powered By',
        summary: 'CRAFT is a product of SimonComputing, an information technology company ' + 
                'with 20 years of experience building secure and innovative software solutions ' + 
                'for government and commercial customers. ',
        link: {
            text: 'Visit our web site to learn more.',
            url: 'https://www.simoncomputing.com'
        }
    };

    const renderContacts = () => {

        if (showSummary) {
            return (
                <div className='content'>
                    {contactInfo.phone + '\n' +
                    contactInfo.email + '\n' +
                    contactInfo.website}
                </div>
            );
        } else {
            return (
                <div className='content contact-only'>
                    <div className='contact-info'>{contactInfo.phone}</div>|
                    <div className='contact-info'>{contactInfo.email}</div>|
                    <div className='contact-info'>{contactInfo.website}</div>
                </div>
            );
           
        }
    };

    return (
        <div className={rootClassName} id='contact'>
            <div className='color-bar'/>
            <Grid className={gridClassName} container id='contact'>
                <Grid className='section' item {...contactProps}>
                    {showSummary && <Typography className='title'>{contactInfo.title}</Typography>}
                    {renderContacts()}
                </Grid>
                {showSummary && <Grid className='section' item xs={12} md={6} order={{xs: 1, md: 2}}>
                    <div className='company-title'>
                        <Typography className='title' variant='subtitle2'>{aboutCompany.title}</Typography>
                        <SCLogo width='225px'/>
                    </div>
                    <div className='content'>
                        {aboutCompany.summary}
                        <Link className='link' href={aboutCompany.link.url} color='inherit'>{aboutCompany.link.text}</Link>
                    </div>
                </Grid>}
            </Grid>
        </div>
    );
};

export default Footer;